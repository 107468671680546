<template>
    <div class="repairDetails">
        <rxNavBar  title="维修完成" ></rxNavBar>
        <div class="content">
            <div class="part">
                <span class="part-subtitle">报修详情</span>
                <div class="otherExpenses-inputpart">
                    <div class="otherExpenses-inputpart-row">
                        <div class="otherExpenses-address">{{repairDetail.roomDetailedAddress}}</div>
                    </div>
                    <div class="otherExpenses-inputpart-column">
                        <div class="otherExpenses-inputpart-row-bottom">
                            <div class="otherExpenses-inputpart-row-left">
                                <span class="part-inputpart-row-header">报修人：</span>
                                <span>{{repairDetail.applicationGlobaluserName ? repairDetail.applicationGlobaluserName : '暂无'}}</span>
                            </div>
                            <div class="otherExpenses-inputpart-row-right">
                                <span class="part-inputpart-row-header">联系方式：</span>
                                <span>{{repairDetail.applicationGlobaluserMoblile ? repairDetail.applicationGlobaluserMoblile : '暂无'}}</span>
                            </div>
                        </div>
                        <div class="otherExpenses-inputpart-row-bottom">
                            <div class="otherExpenses-inputpart-row-left">
                                <span class="part-inputpart-row-header">订单来源：</span>
                                <span>{{repairDetail.fromType ? repairDetail.fromType : '暂无'}}</span>
                            </div>
                            <div class="otherExpenses-inputpart-row-right">
                                <span class="part-inputpart-row-header">维修程度：</span>
                                <span class="part-inputpart-row-redtext">
                                    {{repairDetail.repairStatus ? repairDetail.repairStatus : '暂无'}}</span>
                            </div>
                        </div>
                        <div class="otherExpenses-inputpart-row-bottom">
                            <span class="part-inputpart-row-header">维修类型：</span>
                            <span>{{repairDetail.dictionaryTitle ? repairDetail.dictionaryTitle : '暂无'}}</span>
                        </div>
                        <div class="otherExpenses-inputpart-row-bottom">
                            <span class="part-inputpart-row-header">预约时间：</span>
                            <span class="part-inputpart-row-redtext">
                                {{repairDetail.appointmentTime ? repairDetail.appointmentTime : '暂无' }}</span>
                        </div>
                        <div class="repaireRemarksDiv">
                            <span class="part-inputpart-row-header">维修描述：</span>
                            <span class="part-inputpart-row-wrap">{{repairDetail.afterRentDesprition ? repairDetail.afterRentDesprition : '暂无'}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <!--      申请详情的图片  -->
            <div class="applyDetailImgs" v-if="applyImgList.length>0">
                <van-swipe :loop="false" :width="115" :show-indicators="false">
                    <van-swipe-item   v-for=" (item,i) in applyImgList" :key="item.id">
                        <div class="applyDetailImg">
                            <img  :src="item.path" @click="onChangeApply(i)"/>
                        </div>
                    </van-swipe-item>
                    <div class="custom-indicator">
                        {{applyImgList.length}} 图
                    </div>
                </van-swipe>
            </div>
            <van-image-preview v-model="showApply" :images="applyDetailImgList"  :start-position="start"></van-image-preview>
            <div class="part">
                <div class="otherExpenses-inputpart">
<!--                    <span class="part-subtitle">报修详情</span>-->
                    <div class="otherExpenses-inputpart-column" style="margin-top: 0">
                        <div class="otherExpenses-inputpart-row-bottom">
                            <span class="part-inputpart-row-header">维修人员：</span>
                            <span>{{finishDetail.userName ? finishDetail.userName :'暂无'}}</span>
                        </div>
                        <div class="otherExpenses-inputpart-row-bottom">
                            <span class="part-inputpart-row-header">预计上门时间：</span>
                            <span class="part-inputpart-row-redtext">
                                {{finishDetail.lastDispatchTime ? finishDetail.lastDispatchTime :'暂无'}}</span>
                        </div>
                        <div class="otherExpenses-inputpart-row-bottom">
                            <span class="part-inputpart-row-header">预计费用：</span>
                            <span>{{finishDetail.expectFee ?finishDetail.expectFee+'元' :'暂无'}} </span>
                        </div>
                    </div>
                </div>
                 <!-- <button @click="returnBtn" class="part-button1 part-button-enabled" >退回</button> -->
                <div class="part-inputpart-finishFee">
                    <div class="part-inputpart-row">
                        <span :class=" !laborCost ? 'content-none' : 'content-have' "></span>
                        <span class="part-inputpart-row-header">人工费</span>
                        <span class="content-divide">|</span>
                        <input type="number" v-model="laborCost">
                        <span class="part-inputpart-row-right" style="color: #d8d8d8">元</span>
                    </div>
                     <div id="finishTimePanel" class="part-inputpart-row part-inputpart-finishFee-row" @click="clickFinishTimeShow">
                    <span :class=" 0==finishTime.trim().length ? 'content-none' : 'content-have' "></span>
                    <span class="part-inputpart-row-header">完成时间</span>
                    <span class="content-divide">|</span>
                    <span class="width">{{finishTime}}</span>
                    <img class="part-inputpart-row-right part-inputpart-row-right-timepick" src="../../../assets/images/timepick.png">
                </div>
                <div>
                    <van-popup v-model="isFinishTimeShow" position="bottom" class="selectTimePopup">
                            <van-datetime-picker v-model="currentDate" type="datetime" title="选择年月日" item-height="35px" @confirm="finishTimeConfirm" @cancel="finishTimeCancel"/>
                    </van-popup>
                </div>
                </div>

                <!-- 添加零件 -->
                <div class="part">
                    <div class="part-subtitle2" style="color:#ff5d3b;font-size: 14px;" v-if="oldData">*此为老数据,请重新维护零件信息 </div>
                    <span class="part-subtitle2">添加零件 ( 如有其他零件费用,请点击新增条目 ) </span>
                </div> 
                
                <div>   
                    <div class="houseConfiguration" v-for="(item , i) in houseConfigurationList" :key="i">
                        <!-- 红色取消按钮 -->
                        <div class="cancelBtn" @click="cancelBtn(i)" v-if="item.source!='1'">
                            <div class="cancelImg"></div>
                        </div>
                        <!-- 配置详情 -->
                        <div class="houseDetail">
                            <!-- 是否库出 -->
                            <div class="configuration">
                                <span :class=" !houseConfigurationList[i].type ? 'content-none' : 'content-have' "></span>
                                <span class="part-inputpart-row-header">来源</span>
                                <span class="content-divide">|</span>
                                <div class="rightText">
                                    <span class="passText" :class="{'choosedStyle':houseConfigurationList[i].type == '0'}" @click="chooseUser(i)">零件供应商</span>
                                    <span class="content-divide"> | </span>
                                    <span class="rejectText" :class="{'choosedStyle':houseConfigurationList[i].type == '1'}" @click="chooseLibrary(i)">库出</span>
                                </div>
                            </div>
                            <!-- 否库出 -->
                            <div v-if="houseConfigurationList[i].type == '0'">
                                    <!-- 分割线3 -->
                                <div class="ruleOne"></div>
                                <!-- 供应商 -->
                                <div class="configuration">
                                    <span :class=" !houseConfigurationList[i].userName ? 'content-none' : 'content-have' "></span>
                                    <span class="part-inputpart-row-header" >零件供应商</span>
                                    <span class="content-divide" >|</span>
                                    <span class="configurationSelect" @click="chooseOne(i,item)">
                                        {{houseConfigurationList[i].userName}}
                                    </span>
                                    <img src="../../../assets/images/triangle.png" :class="!supplierShow?'downImage':'upImage'">
                                </div>

                                <van-popup v-model="supplierShow" position="bottom">
                                    <div class="searchDiv">
                                        <van-search
                                            placeholder="请输入"
                                            v-model="userName"
                                            size="large"
                                            @input="getQuerySupplierList()"
                                        />
                                    </div>
                                    <van-picker
                                        show-toolbar
                                        value-key="userName"
                                        :columns="supplierList"
                                        @cancel="supplierShow = false"
                                        @confirm="selectGoodsTitleType"
                                    />
                            </van-popup>
                                <!-- 分割线3 -->
                                <div class="ruleOne"></div>
                                <div class="configuration">
                                    <span :class=" !houseConfigurationList[i].componentName ? 'content-none' : 'content-have' "></span>
                                    <span class="part-inputpart-row-header">零件名称</span>
                                    <span class="content-divide">|</span>
                                    <input v-model="houseConfigurationList[i].componentName">
                                </div>
                                <!-- 分割线3 -->
                                <div class="ruleOne"></div>
                                <!-- 零件费 -->
                                <div class="configuration">
                                    <span :class=" !houseConfigurationList[i].componentCost ? 'content-none' : 'content-have' "></span>
                                    <span class="part-inputpart-row-header">零件费</span>
                                    <span class="content-divide">|</span>
                                    <input type="number" v-model="houseConfigurationList[i].componentCost"
                                    >
                                    <span class="part-inputpart-row-right" style="color: #d8d8d8">元</span>
                                </div>
                                <!-- 分割线4 -->
                                <div class="ruleOne"></div>
                            </div>
                            <!-- 是库出 -->
                            <div v-else>
                                    <!-- 分割线3 -->
                                <div class="ruleOne"></div>
                                <!-- 供应商 -->
                                <div class="configuration">
                                    <span :class=" !houseConfigurationList[i].userName ? 'content-none' : 'content-have' "></span>
                                    <span class="part-inputpart-row-header" >仓库</span>
                                    <span class="content-divide" >|</span>
                                    <span class="configurationSelect">
                                        {{houseConfigurationList[i].userName}}
                                    </span>
                                </div>
                                <!-- 分割线3 -->
                                <div class="ruleOne"></div>
                                <div class="configuration">
                                    <span :class=" !houseConfigurationList[i].componentName ? 'content-none' : 'content-have' "></span>
                                    <span class="part-inputpart-row-header">零件名称</span>
                                    <span class="content-divide">|</span>
                                    <span class="configurationSelect" @click="chooseComponent(i,item)">
                                        {{houseConfigurationList[i].componentName}}
                                    </span>
                                    <img src="../../../assets/images/triangle.png" :class="!goodsShow?'downImage':'upImage'">
                                </div>
                                <van-popup v-model="goodsShow" position="bottom">
                                    <!-- <div class="searchDiv">
                                        <van-search
                                            placeholder="请输入"
                                            v-model="componentName"
                                            size="large"
                                            @input="getQuerySupplierList()"
                                        />
                                    </div> -->
                                    <van-picker
                                        show-toolbar
                                        value-key="spanName"
                                        :columns="libraryGood.list"
                                        @cancel="goodsShow = false"
                                        @confirm="selectGoods"
                                    />
                            </van-popup>
                                <!-- 分割线3 -->
                                <div class="ruleOne"></div>
                                <!-- 零件费 -->
                                <div class="configuration">
                                    <span :class=" !houseConfigurationList[i].componentCost ? 'content-none' : 'content-have' "></span>
                                    <span class="part-inputpart-row-header">零件费</span>
                                    <span class="content-divide">|</span>
                                    <input v-model="houseConfigurationList[i].componentCost" disabled
                                    >
                                    <span class="part-inputpart-row-right" style="color: #d8d8d8">元</span>
                                </div>
                                <!-- 分割线4 -->
                                <div class="ruleOne"></div>
                            </div>
                            
                            <!-- 上传图片 -->
                            <div class="upLoadImg" >
                                <van-uploader v-model="houseConfigurationList[i].picComponentList" multiple
                                            accept="image/*"
                                            preview-size="80px"
                                            :after-read="afterReadTest"
                                            @delete="deletePreview"
                                            style="padding-left: 3%"/>
                            </div>
                            <div class="upLoadText">
                                <span >*请务必上传</span>
                            </div>
                            <!-- 分割线5-->
                            <div class="ruleOne"></div>
                            <!-- 备注 -->
                            <textarea class="textareaRemarks" v-model="houseConfigurationList[i].componentDescribe" placeholder="描述"></textarea>
                            <div>

                            </div>
                        </div>
                        </div>

                        <div class="newAdd" @click="addInfo()">
                            <u >+ 新增条目</u>
                        </div>
                </div>

                <div class="part-inputpart">
                    <div class="part-inputpart-textarea">
                        <div class="part-inputpart-row">
                            <span class="otherExpenses-row-enablenon part-inputpart-row-header">处理反馈</span>
                            <span class="smallgraytext">(必填)</span>
                        </div>
                        <textarea v-model="afterRentDesprition" @input="countCharacters">
                        </textarea>
                        <span class="smallgraytext" style="margin-left: 250px;"> {{ charCount }} / {{ maxCharCount }}</span>
                        <div class="part-inputpart-row2">
                          <div>处理反馈填写要求：</div>
                          <div>1、维修什么地方？例如维修锁体，或者更换锁芯</div>
                          <div>2、维修方案？例如洗手池堵了，更换排水管还是疏通下水了</div>
                          <div>3、维修费用构成？例如零件的价格、数量、人工费等</div>
                        </div>

                    </div>
                    <div class="part-uploadpic-content">
                        <!--           上传图片按钮-->
                        <van-uploader class="part-uploadpic-module"  v-model="imgList" multiple :max-count="10" :after-read="afterReadTest" image-fit="contain" @delete="deletePreview">
                            <div style="position:relative;width: 90px;height: 90px;line-height: 70px; border-radius: 8px; background-color: #fafafa; margin-left: 10px; margin-top: 10px;text-align: center;color: #d8d8d8;">
                                <div>+</div>
                                <span style="position: absolute;top: 20%;left: 25%;;font-size: 12px;">上传图片</span>
                            </div>
                        </van-uploader>
                    </div>
                </div>
                <button class="part-button" :class="(!laborCost||!finishTime||disabled||(afterRentDesprition==undefined || afterRentDesprition=='') || (houseConfigurationList.length != 0 && !repaireRule()) ? '' : 'part-button-enabled')"
                        :disabled="(!laborCost||!finishTime||disabled||(afterRentDesprition==undefined || afterRentDesprition=='') || (houseConfigurationList.length != 0 && !repaireRule()))"
                        @click="saveAfterRenBtn" >提交</button>
                <!-- <button class="part-button" :class="(!laborCost||!finishTime||disabled||(afterRentDesprition==undefined || afterRentDesprition=='') || (houseConfigurationList.length != 0 && !repaireRule()) ? '' : 'part-button-enabled')"
                        @click="saveAfterRenBtn" >提交</button>         -->
            </div>
          <div class="bottom_btn">
            <van-button size="large" class="btn" @click="showTelephone()" v-if="isShowTelephone">联系租客</van-button>
          </div>
        </div>
        <loadingFlag v-if="loadingFlag"></loadingFlag>
      <diaMobileList :mobileList="mobileList" dialogTitle="联系租客" :mobileShow="isMobileShow" @hideDialog="hideDialog"> </diaMobileList>
       <!--    ======================================退回弹框=========================================-->
        <van-popup v-model="returnShow" class="van-popup--bottom" position="bottom" :style="{ height: '45%' }">
            <img class="cancelOrdersImg" src="../../../assets/images/colon.png">
            <div class="cnacelOrdersText">确认</div>
            <div class="confirmCancelOrdersText">退回该保修单？</div>

            <div
            id="recommendTypePanel"
            class="part-inputpart-row"
            @click="isReturnShow = !isReturnShow"
          >
            <div
              :class="
                !sendBackType.id
                  ? 'content-none'
                  : 'content-have'
              "
            ></div>
            <span class="part-inputpart-row-header">退回原因</span>
            <span class="content-divide">|</span>
            <span
              class="part-inputpart-row-graytext"
              :class="
                !sendBackType.id
                  ? ''
                  : 'part-inputpart-row-text'
              "
              >{{ sendBackType.dictionaryTitle }}</span
            >
            <img
              class="part-inputpart-row-right"
              :class="
                isReturnShow
                  ? 'part-inputpart-row-right-upArrow'
                  : 'part-inputpart-row-right-downArrow'
              "
              src="../../../assets/images/triangle.png"
            />
          </div>

           <div class="part-inputpart-row">
            <div
             :class="
                returnReason == ''
                  ? 'content-none'
                  : 'content-have'
              "
            ></div>
            <span class="part-inputpart-row-header">描述</span>
            <span class="content-divide">|</span>
            <input v-model="returnReason" placeholder="输入退回描述" />
          </div>

           <!-- <textarea placeholder="输入退回描述" v-model="returnReason"/> -->

            <van-button  @click="cancelReturnBtn"
                        :class="!sendBackType.id||returnReason == '' ? 'saveButton_Disable':'saveButton_Enable'"
                        :disabled="!sendBackType.id||returnReason == ''||disabled">确定
            </van-button>
        </van-popup>

        <van-popup v-model="isReturnShow" position="bottom">
        <van-picker
            show-toolbar
            :columns="sendBackArr"
            @cancel="isReturnShow = false"
            :default-index="sendBackIndex"
            value-key="dictionaryTitle"
            @confirm="selectSendBackOption"
        />
        </van-popup>

    </div>
</template>

<script>
import {
  NavBar,
  Image as VanImage,
  ImagePreview,
  DatetimePicker,
  Picker,
  Uploader,
  Popup,
  Swipe,
  SwipeItem,
  Loading,
  Overlay,
  Search,
  Button
} from 'vant'
    import loadingFlag from "./LoadingFlag";
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    import diaMobileList from "../../../components/rongxun/rx-dialMobile/diaMobileList";

    import {getStaffId, globaluserId,dealImage, responseUtil} from "../../../libs/rongxunUtil";
    import {
      base64Upload,
      queryAfterRentRepair, queryComponentSupplierList,queryGlobalUser,
      repairFinishDetail,
      saveAfterRentRepair, queryGlobalUsersNameAndMobile,queryBaseData,
      sendBackRepair,
      queryLibraryGood
    } from "../../../getData/getData";
    import util2 from '../../contract/util2'
    import Vue from "vue";
import codeLoginVue from '../../login/codeLogin.vue';
    Vue.use(Popup);
    Vue.use(Picker);
    export default {
        name: "RepairDetails",

        components: {
            [NavBar.name]: NavBar,
            [DatetimePicker.name]: DatetimePicker,
            [Uploader.name]: Uploader,
            [VanImage.name]:VanImage,
            [Popup.name]: Popup,
            [ImagePreview.Component.name]: ImagePreview.Component,
            rxNavBar,
            [Swipe .name]:Swipe,
            [SwipeItem .name]:SwipeItem,
            [Loading .name]:Loading,
            [Overlay .name]:Overlay,
            [Search.name]: Search,
          [Button.name]: Button,
            loadingFlag,
          diaMobileList,
        },

        data() {
            return {
                oldData: false,
                //零件添加的div
                houseConfigurationList : [],
                libraryGood : {},
                index:0,//记录物品数组下标
                returnShow: false,
                isReturnShow: false,
                sendBackType: {},
                returnReason: '',
                sendBackArr: [],
                sendBackIndex: "",
                roleType: '1',  //标识，用于判断入口是我的页面的租后工单（装修工人等 是 0   否 1）
                loadingFlag : false,
                disabled : false,
                //============================报修详情===============
                //初始化时用的租后ID
                id : '',
                //初始化返回的详情信息
                repairDetail : {},
                isFinishTimeShow: false,
                currentDate: new Date(),
                applyImgList : [],
                // 反馈图片列表
                imgList: [],
                showApply : false,
                applyDetailImgList : [],
                start : 0,
                //===========================维修详情===============
                //维修详情初始化数据
                finishDetail : {},
                // 完成费用
                lastFee: '',
                laborCost:'',
                // componentCost:'',
                componentCostTotal:'',
                finishFeeImgList:[],
                // 完成时间
                finishTime: '',
                // 处理反馈
                afterRentDesprition: '',
                userName:"",
                componentName:"",
                goodsShow:false,    //供应商列表弹出/隐藏
                supplierShow:false,    //供应商列表弹出/隐藏
                supplierList:[],
                application:{},
                isShowTelephone:true,  //公区显示
                isMobileShow:false,
                mobileList:[
                  {
                    userName:'A间',
                    mobile:'15322223333'
                  },
                  // {
                  //   userName:'B间',
                  //   mobile:'15322223333'
                  // },
                  // {
                  //   userName:'A间',
                  //   mobile:'15322223333'
                  // },
                  // {
                  //   userName:'A间',
                  //   mobile:'15322223333'
                  // },
                  // {
                  //   userName:'A间',
                  //   mobile:'15322223333'
                  // },
                  // {
                  //   userName:'A间',
                  //   mobile:'15322223333'
                  // },
                ],
              roomHouse_id:undefined,
              setHouse_id:undefined,
              houseType:undefined,
              charCount: 0,
              maxCharCount: 280
            }
        },
        watch:{
        //   "componentCost":function(newVal){
        //      this.componentCostChange(newVal)
        //   }
        },
        created() {
            this.id = this.$route.query.id
            this.repair_id = this.$route.query.repair_id
            this.roleType = this.$route.query.roleType  //是否是装修负责人  0 是    1 否
            this.roomHouse_id = this.$route.query.roomHouse_id
            this.setHouse_id = this.$route.query.setHouse_id
            this.houseType = this.$route.query.houseType
            this.charCount = 0
            this.queryAfterRentRepair()
            this.repairFinishDetail()
            this.queryLibraryGood()
            this.setIsShowTelephone()
            this.initDb()
        },
        methods: {
            countCharacters() {
            this.charCount = this.afterRentDesprition.length;
        },
             //    新增条目的点击方法
            addInfo(){
                this.houseConfigurationList.push({
                    type:'0',
                    componentCost: '',
                    componentDescribe: '',
                    componentName:'',
                    userName:'',
                    componentSupplier_id:'',
                    //图片的List
                    picComponentList : []
                });
            },
            //删除条目的点击方法
            cancelBtn(i){
                this.houseConfigurationList.splice(i,1);  //删除index为i,位置的数组元素
                this.index = 0
            },
            deletePreview(file){
                console.log("deletePreview")
                console.log("imgList:",this.imgList)
                this.$forceUpdate()
            },
            //初始化字典=====================================================
            initDb() {
                let that = this
                let initData = {}
                initData.dbName = ['sendBackReason']
                initData.fdName = []
                queryBaseData(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.sendBackArr = response.data.data.sendBackReason;
                    })
                })
            },
           //退回按钮点击弹窗
            returnBtn(item) {
                this.sendBackType = {}
                this.returnReason = ''
                this.returnShow = true
                this.repair_id = this.repair_id
            },
            //退回确认按钮
            cancelReturnBtn() {
                let that = this
                    console.log(that.sendBackType)

                if (!that.sendBackType.id) return;
                if (that.returnReason == '') return;

                this.disabled = true
                let initData = {}
                initData.staff_id = getStaffId()
                initData.repair_id = that.repair_id
                initData.sendBackReason_id = that.sendBackType.id
                initData.remarks = that.returnReason
                sendBackRepair(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        responseUtil.alertMsg(that, '订单退回成功')
                        that.returnShow = false
                        that.disabled = false
                        that.skip()
                    })
                })
            },
            //点击下拉框选项 进行数据绑定
            selectSendBackOption(item, index) {
            if (item) {
                this.sendBackType = item;
                this.sendBackIndex = index;
            }
            this.isReturnShow = false;
            },  
            //是否显示联系租客
          setIsShowTelephone(){
            if(this.roomHouse_id==undefined && this.houseType=='1'){  //合租且没有roomhouseID
              this.isShowTelephone = true
            }else{
              this.isShowTelephone = false
            }
          },
            onChangeApply(i) {
                this.start=i
                this.showApply=true
            },
            //返回上一层

            //报修详情初始化
            queryAfterRentRepair(){
                let that = this
                let initData = {}
                initData.id = that.id
                queryAfterRentRepair(initData).then(function (response) {
                    responseUtil.dealResponse(that,response,()=>{
                        that.repairDetail = response.data.data
                        that.applyImgList = response.data.data.imgList
                        for(let i=0;i<that.applyImgList.length;i++){
                            that.applyDetailImgList.push(that.applyImgList[i].path)
                        }
                    })
                })
            },
            //维修完成维修详情初始化
            repairFinishDetail(){
                let that = this
                let initData = {}
                initData.user_id=globaluserId()
                initData.id = that.repair_id
                repairFinishDetail(initData).then(function (response) {
                    responseUtil.dealResponse(that,response,()=>{
                        if(response.data.data.data.componentCost && response.data.data.imgComponentList){
                            if(Number(response.data.data.data.componentCost) >= 0 && response.data.data.imgComponentList.length > 0){//判断为老数据
                            that.oldData = true;
                        }
                        }
                        
                        that.finishDetail = response.data.data.data
                        that.lastFee = that.finishDetail.expectFee
                        that.laborCost = response.data.data.data.laborCost
                        that.finishTime = response.data.data.data.finishTime ? response.data.data.data.finishTime : ''
                        console.log(that.finishDetail)
                         that.getQuerySupplierList()

                        if(response.data.data.repairSupplierMapList && response.data.data.repairSupplierMapList.length > 0){
                            let val = response.data.data.repairSupplierMapList
                            let len = response.data.data.repairSupplierMapList.length
                            for(let i = 0; i < len; i++){
                                if(!val[i].repairSupplier.type){
                                    val[i].repairSupplier.type = '0'
                                }
                                that.houseConfigurationList[i] = val[i].repairSupplier
                                 that.houseConfigurationList[i].picComponentList = val[i].picComponentList
                            }
                        }
                        // that.getQuerySupplierList()
                    })
                })
            },
            //租后维修完成仓库查询接口
            queryLibraryGood(){
                this.libraryGood = {}
                let that = this
                let initData = {}
                initData.id = that.id
                queryLibraryGood(initData).then(function (response) {
                    responseUtil.dealResponse(that,response,()=>{
                       that.libraryGood = response.data.data
                       console.log('libraryGood',that.libraryGood)

                       if(that.libraryGood.list.length > 0){
                        that.libraryGood.list.filter(item =>{
                            item.spanName = item.componentName + " (库存" + item.availableInventory + ")"
                        })
                       }

                    })
                })
            },
            repaireRule(){
                if(this.houseConfigurationList.length <= 0){
                    return true
                } else {
                    let len = this.houseConfigurationList.length
                    let val = this.houseConfigurationList
                    let flagList = []
                    for(let i = 0; i < len; i++){
                        if(val[i].componentCost == '' || val[i].userName == '' || val[i].componentName == '' || val[i].picComponentList.length == 0){
                            // return false
                            flagList.push(i)
                        } else {
                            // return true
                        }
                    }
                    if(flagList.length > 0){
                        return false
                    } else {
                        return true
                    }
                    

                }

            },
            //维修完成保存接口
            saveAfterRenBtn(){
              console.log("save:",this.houseConfigurationList)

                this.disabled = true
                this.saveAfterRentRepair()
                setTimeout(()=>{
                    this.disabled = false
                },2000)

            },
            //维修完成保存接口
            saveAfterRentRepair (){
                let that = this
                let initData = {}
                if(that.charCount > that.maxCharCount){
                  responseUtil.alertMsg(this,'处理反馈字数超过280字,请酌情更改')
                  return
                }
                if(that.roleType == '1'){
                    initData.staff_id =  getStaffId()
                }else if(that.roleType == '0'){
                    initData.staff_id = '1'  //如果登录人是装修负责人（非员工），则使用传固定值“1”
                }
                if(that.afterRentDesprition==undefined || that.afterRentDesprition==''){
                  responseUtil.alertMsg(this,'处理反馈不可为空')
                  return
                }
                that.loadingFlag = true
                if(that.houseConfigurationList.length > 0){
                    let len = that.houseConfigurationList.length
                    let val = that.houseConfigurationList
                    that.componentCostTotal = 0
                    for(let i = 0; i < len; i++){
                        that.componentCostTotal += Number(val[i].componentCost)
                    }
                }
                console.log('componentCostTotal:',that.componentCostTotal)
                console.log('laborCost:',that.laborCost)
                // initData.staff_id =  getStaffId()
                initData.id =that.id
                initData.description_id = that.repairDetail.description_id
                initData.lastFee = util2.NumberAdd(that.laborCost,that.componentCostTotal)
                initData.laborCost = that.laborCost
                // initData.componentCost = that.componentCost
                // initData.componentSupplier_id = this.application.supplier_id
                initData.finishTime = new Date(that.finishTime)
                initData.afterRentDesprition = that.afterRentDesprition
                initData.imgList = that.imgList
                // initData.picComponentList = that.finishFeeImgList
                initData.componentSupplierList = that.houseConfigurationList

                console.log('initData:',initData)
                saveAfterRentRepair(initData).then(function (response) {
                    if(response.data.code == '-1'){
                        that.loadingFlag = false
                    }
                    responseUtil.dealResponse(that,response,()=>{
                        responseUtil.alertMsg(that,'维修完成')
                        that.skip()
                        that.loadingFlag = false
                    })
                })
            },

            //图片多选上传
            afterReadTest(file){
                // this.loadingFlag = true;
                let fileLength = parseInt(file.length) - parseInt(1)
                if(file.length > 1){
                    for(let i = 0; i<file.length;i++){
                        let fileDetail = file[i];
                        dealImage(fileDetail,fileDetail.content,400,this.afterRead,i,fileLength);
                    }
                }else{
                    dealImage(file,file.content,400,this.afterRead);
                }
            },

            afterRead(file,base64Str,i,fileLength){
                let that = this
                let initData = {
                    base64: base64Str
                }
                that.loadingFlag = true,
                    base64Upload(initData).then(function (response) {
                        responseUtil.dealResponse(that, response, () => {
                            file.path = response.data.data.path
                            // that.loadingFlag = false
                            if(i == fileLength){
                                that.loadingFlag = false
                            }
                        })
                    })
            },

            //维修完成跳转页面
            skip(){
                this.$router.go(-1)
            },
            componentCostChange(){
                if(this.componentCost<=0 || this.componentCost==''){
                  this.application.supplier_id=''
                  this.application.supplierName=''
                  this.finishFeeImgList=[]
                }
            },
            chooseUser(i) {
                this.houseConfigurationList[i].componentCost = '';
                this.houseConfigurationList[i].componentDescribe = '';
                this.houseConfigurationList[i].componentName = '';
                this.houseConfigurationList[i].userName = '';
                this.houseConfigurationList[i].componentSupplier_id = '';
                this.houseConfigurationList[i].picComponentList = [];

                this.houseConfigurationList[i].library_id = '';
                this.houseConfigurationList[i].unitId = '';
                this.houseConfigurationList[i].year = '';
                this.houseConfigurationList[i].goodsallocationId = '';

                this.houseConfigurationList[i].type = '0';

                this.$forceUpdate()
            },
            chooseLibrary(i) {
                let that = this
                if(this.libraryGood.library_id == '-1'){
                    responseUtil.alertMsg(that,'无仓库匹配')
                    return
                }
                if(this.libraryGood.list.length == 0){
                    responseUtil.alertMsg(that,'当前仓库没有可用零件')
                    return
                }
                 this.houseConfigurationList[i].componentCost = '0';
                this.houseConfigurationList[i].componentDescribe = '';
                this.houseConfigurationList[i].componentName = '';
                this.houseConfigurationList[i].userName = this.libraryGood.library_name;
                this.houseConfigurationList[i].componentSupplier_id = this.libraryGood.library_id;
                this.houseConfigurationList[i].picComponentList = [];

                this.houseConfigurationList[i].library_id = '';
                this.houseConfigurationList[i].unitId = '';
                this.houseConfigurationList[i].year = '';
                this.houseConfigurationList[i].goodsallocationId = '';

                this.houseConfigurationList[i].type = '1';
                this.$forceUpdate()
            },
            //仓库零件选择
            chooseComponent(i,item){
                this.index = i
                this.goodsShow = !this.goodsShow
            },
            //仓库零件下拉
            selectGoods(item){
                console.log(item)
                this.houseConfigurationList[this.index].componentName = item.componentName
                this.houseConfigurationList[this.index].library_id = item.library_id
                this.houseConfigurationList[this.index].unitId = item.unitId
                this.houseConfigurationList[this.index].year = item.year
                this.houseConfigurationList[this.index].goodsallocationId = item.goodsallocationId
                
                if(item.componentCost == 0){
                    this.houseConfigurationList[this.index].componentCost = '0'
                }else{
                    this.houseConfigurationList[this.index].componentCost = item.componentCost
                }
                this.goodsShow = false
            },
            //商品分类选择
            chooseOne(i,item){
                // if(item.source=='1'){
                //       return
                // }
                this.index = i
                this.supplierShow = !this.supplierShow
            },
            
            //供应商下拉
            selectGoodsTitleType(item){
                console.log(item)
                this.houseConfigurationList[this.index].userName = item.userName
                this.houseConfigurationList[this.index].componentSupplier_id = item.id
                this.supplierShow = false
            },
            //确认供应商
            confirmSupplier(e){
                this.application.supplier_id=e.id
                this.application.supplierName=e.userName
                this.supplierShow=false
            },
            //供应商列表
            getQuerySupplierList(){
                let that=this
                let initData={

                }
                initData.userName = this.userName
                initData.mappingGlId = this.finishDetail.id
                queryGlobalUser(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.supplierList=response.data.data.list
                    })
                })
            },

            //完成时间
            clickFinishTimeShow() {
                this.isFinishTimeShow = !this.isFinishTimeShow;
                var panel = document.getElementById('finishTimePanel')
                if(panel){
                    document.addEventListener('click',e =>{
                        if(!panel.contains(e.target)){
                            this.isFinishTimeShow = false
                        }
                    })
                }
            },
            finishTimeConfirm(value){
                var date = new Date(value)
                this.finishTime = this.formatDate(date)
                this.isFinishTimeShow = false
            },
            // 格式化日期方法
            formatDate(dateTime) {
                console.log(dateTime.getMinutes())
                let year = `${dateTime.getFullYear()}`;
                let month  = `${dateTime.getMonth() + 1}` ;
                let date = `${dateTime.getDate()}` ;
                let hours = `${dateTime.getHours()}` ; ;
                let minutes = `${dateTime.getMinutes()}` ; ;
                if(month < 10){
                    month = `0${dateTime.getMonth() + 1}`
                }
                if(date < 10){
                    date = `0${dateTime.getDate()}`
                }
                if(hours < 10){
                    hours = `0${dateTime.getHours()}`
                }
                if( minutes < 10){
                    minutes = `0${dateTime.getMinutes()}`
                }
                return year+ '/' + month+ '/' + date + ' ' + hours + ':' + minutes;

            },
            finishTimeCancel(){
                this.isFinishTimeShow = false
            },

            previewImg(index){
                ImagePreview(
                    {
                        images: this.acceptComplaintsInfo.picList,
                        startPosition: index,
                        onClose() {
                            // do something
                        },
                    }
                )
            },

            showTelephone(){
                let that = this
                let initData = {}
                initData.setHouse_id = this.setHouse_id
                queryGlobalUsersNameAndMobile(initData).then(function (response) {
                  responseUtil.dealResponse(that, response, () => {
                    that.mobileList = response.data.data.data
                    that.isMobileShow = !that.isMobileShow;

                  })
                })
            },
            //关闭拨打电话弹窗
            hideDialog() {
                this.isMobileShow = false;
            },
        }

    }
</script>

<style scoped lang="less">
    .repaireRemarksDiv{
        word-wrap: break-word;
    }
    .content {
        font-size: 14px;
    }

    .part {
        margin: 15px 15px 0 ;
    }

    .part-subtitle {
        color: #ff5d3b;
        font-size: 12px;
        font-weight: 900;
        position: relative;
        top: 8px;
    }

    .part-inputpart {
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        overflow: hidden;
    }

    .part-inputpart-finishFee-row{
        margin-top: 0px!important;
    }

    .part-inputpart-row {
        display: flex;
        flex-direction: row;
        border-radius: 8px;
        align-items: center;
        height: 45px;
        background-color: #ffffff;
        margin-top: 15px;
    }
    .part-inputpart-row2 {
      width: 100%;
      box-sizing: border-box;
      padding: 0.1rem 0.3rem 0rem;
      color: #7d7b7b;
      div{
        clear: both;
        line-height: 0.55rem;
        white-space:pre-wrap;
      }
      div:nth-of-type(2),div:nth-of-type(3),div:nth-of-type(4){
        text-indent: 1em;
      }
    }

    .part-inputpart-row-header {
        font-weight: 900;
        font-size: 15px;
        white-space: nowrap;
    }

    .otherExpenses-inputpart {
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        overflow: hidden;
        margin-top: 10px;
    }

    .otherExpenses-inputpart-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #ffffff;
        margin-top: 2px;
    }

    .part-inputpart-row input {
        border: 0;
        width: 100%;
    }

    .otherExpenses-address{
        padding: 15px 10px 15px 15px;
        font-size: 16px;
        font-weight: 900;
    }

    .otherExpenses-inputpart-row-bottom {
        margin-bottom: 5px;
        display: flex;
        align-items: baseline;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .otherExpenses-inputpart-row-left {
        display: inline-block;
        width: 47%;
        overflow: hidden;
        /*padding-right: 15px;*/
        margin-right: 5px;
    }

    .otherExpenses-inputpart-row-right {
        display: inline-block;
        width: 53%;
    }

    .otherExpenses-inputpart-column {
        display: flex;
        flex-direction: column;
        height: auto;
        padding: 16px 15px;
        justify-content: space-between;
        background-color: #ffffff;
        margin-top: 2px;
    }

    .part-inputpart-row-right {
        float: right;
        margin-right: 15px;
    }

    .part-inputpart-row-right-downArrow {
      width: 9px;
    }

    .part-inputpart-row-right-upArrow {
      width: 9px;
      transform: rotateX(180deg);
    }

    .part-inputpart-row-wrap {
        line-height: 24px;
    }

    .content-have {
        padding: 3px;
        margin: 0 10px 0 15px;
        border-radius: 50%;
        background-color: #ff5d3b;
    }

    .content-none {
        padding: 3px;
        margin: 0 10px 0 15px;
        border-radius: 50%;
        background-color: #c7c7c7;
    }

    .content-divide {
        font-size: 12px;
        color: #efefef;
        margin: 0 10px;
    }

    .otherExpenses-row-enablenon{
        margin-left: 15px;
    }

    .part-inputpart-row-graytext {
        color: #d8d8d8;
        width: 100%;
        font-size: 14px;
    }

    .part-inputpart-row-normaltext {
        color: black;
        //font-weight: 900;
        flex: auto;
    }

    .part-inputpart-row-redtext {
        width: 100%;
        color: #ff5d3b;
    }

    .part-inputpart-textarea {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        background-color: white;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .part-inputpart-textarea div:first-child {
        margin-top: 0;
    }

    .part-inputpart-textarea textarea {
        height: 120px;
        background-color: #FaFaFa;
        border-radius: 8px;
        padding: 10px;
        margin: 0px 15px;
        border: none;
        resize: none;
    }

    .part-inputpart-textarea textarea::-webkit-input-placeholder {
        color: #d8d8d8;
    }
    .part-button1 {
        height: 45px;
        border-radius: 10px;
        background-color: #ededed;
        color: #ffffff;
        text-align: center;
        line-height: 45px;
        font-size: 15px;
        font-weight: 900;
        margin-top: 20px;
        margin-bottom: 10px;
        width: 100%;
        border: none;
    }

    .part-button {
        height: 45px;
        border-radius: 10px;
        background-color: #ededed;
        color: #ffffff;
        text-align: center;
        line-height: 45px;
        font-size: 15px;
        font-weight: 900;
        margin-top: 20px;
        margin-bottom: 90px;
        width: 100%;
        border: none;
    }

    .part-button-enabled {
        background-image: linear-gradient(to right, #ffc274, #ff5d3b);
    }

    .smallgraytext{
        color: #D8D8D8;
        font-size: 12px;
        margin-bottom: -3px;
    }

    .part-inputpart-row-right-timepick{
        width: 20px;
    }

    .part-inputpart-dropDownList{
        height: 23%;
        /*垂直方向滚动*/
        /*overflow-y: scroll;*/
        overflow: auto;
        width: 92.5%;
        position: absolute;
        z-index: 99;
    }

    .part-uploadpic-content{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        background-color: white;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        width: 100%;
        margin: 0 auto;
    }

    .part-uploadpic-module{
        width: 100%;
        margin: 20px 8px;
    }

    .part-uploadpic-module-pic{
        width: 80px;
        height: 80px;
        border-radius: 8px;
    }

    .part-uploadpic-module-supplier{
        width: 100%;
        margin: 0px 8px 20px;
    }

    .part-inputpart-cardpic-text{
        position: absolute;
        top: 72px;
        left: 33px;
        font-size: 12px;
        color: #d8d8d8;
    }

    .part-inputpart-cardpic-button{
        display: inline-block;
        background: url("../../../assets/images/grayAdd.png") center center no-repeat;
        width: 90px;
        height: 90px;
        border-radius: 8px;
        background-size: 30px;
        background-color: #fafafa;
        margin-left: 10px;
        margin-top: 10px;
    }

    .width{
        width: 100%;
    }

    .selectTimePopup{
        height: 6rem;
        width: 100%;
    }
    /*    申请详情图片DIV*/
    .applyDetailImgs{
        position: relative;
        height: 108px;
        width: 92%;
        margin: 15px auto ;
        background-color: #FFFFFF;
        border-radius: 8px;
    /*图片*/
    .applyDetailImg{
        width: 100px;
        height: 75px;
        border-radius: 8px;
        margin: 15px 0px 18px 4px ;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #e1eaec;
    img{
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }
    }
    /*    轮播的显示图片数*/
    .custom-indicator{
        position: absolute;
        left: 15px;
        top: 20px;
        padding: 1px 7px;
        font-size: 12px;
        background: rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        color: white;
    }
    }
    .vanLoading{
        position: fixed;
        z-index: 101;
        top: 50%;
        left: 47%;
    }
    .vanOverlay{
        z-index: 100;
    }

    .bottom_btn{
      position: fixed;
      border-top: 1px solid rgb(243, 243, 243);
      width: 100%;
      //height: 1.7rem;
      bottom: 0px;
      background-color: white;
      display: flex;
      justify-content: flex-end;
      padding: 10px;
      box-sizing: border-box;
      .btn {
        width: 120px;
        //margin-right: 10px;
        // margin-top: 10px;
        border-radius: 8px;
        background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        background: -webkit-linear-gradient(left,
        rgba(255, 194, 116, 1),
        rgba(255, 93, 59, 1));
        /* Safari 5.1 - 6.0 */
        background: -o-linear-gradient(right,
        rgba(255, 194, 116, 1),
        rgba(255, 93, 59, 1));
        /* Opera 11.1 - 12.0 */
        background: -moz-linear-gradient(right,
        rgba(255, 194, 116, 1),
        rgba(255, 93, 59, 1));
        /* Firefox 3.6 - 15 */
        background: linear-gradient(to right,
        rgba(255, 194, 116, 1),
        rgba(255, 93, 59, 1));
        color: rgb(255, 255, 255);
        font-size: 16px;
      }
    }
      /*弹窗样式*/
    .van-popup--bottom {
        background-color: #f8f8f8;
        //派单
        overflow: hidden;

        //取消订单
        .cancelOrdersImg {
            float: left;
            margin: 20px 5px 0 15px;
            width: 15px;
            height: 15px;
        }

        //取消订单
        .cnacelOrdersText {
            margin-top: 18px;
            font-size: 15px;
            font-weight: bold
        }

        //取消订单
        .confirmCancelOrdersText {
            margin-left: 35px;
            margin-bottom: 15px;
            font-size: 15px;
            font-weight: bold
        }
    }
     /*保存按钮不可点击样式*/
    .saveButton_Disable {
        /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
        background-color: rgba(184, 184, 184, 0.2);
        width: 345px;
        height: 50px;
        border-radius: 8px;
        margin: 25px 50px 30px 15px;
        font-size: 18px;
        color: white;
        line-height: 1.22rem;
        text-align: center;
    }

    .saveButton_Enable {
        background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
        color: white;
        width: 345px;
        height: 50px;
        border-radius: 8px;
        margin: 25px 50px 15px 15px;
        font-size: 18px;
        line-height: 1.22rem;
        text-align: center;
    }
    .part-inputpart-row-text {
        color: black;
        }
     input {
        font-size: 14px;
        margin-left: 15px;
        // background-color: #f4f4f4;
        border: 0;
        width: 250px;
    }

    input::-webkit-input-placeholder {
        color: #d9d9d9;
    }

    /*新增条目*/
    .newAdd{
        width: 25%;
        margin-left: 250px;
        margin-bottom: 20px;
        color: #f86513;
        font-size: 13px;
        text-align: right;
    }
    .part-subtitle2 {
        color: #ff5d3b;
        font-size: 12px;
        font-weight: 900;
        position: relative;
        // top: 8px;
    }
    /*    零件配置信息表*/
    .houseConfiguration {
        width: 92%;
        display: flex;
        margin: 5px auto 20px;
        /*取消按钮*/

        .cancelBtn {
            width: 22px;
            height: 20px;
            border-radius: 50%;
            background-color: #D7655A;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 13px;
            .cancelImg {
                height: 2px;
                width: 12px;
                background-color: white;
            }
        }
        /*    配置详情*/
        .houseDetail{
            width: 92%;
            background-color: white;
            margin-left: 5px;
            border-radius: 8px;
            /*配置名称*/
            .configuration{
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 50px;

                .content-have {
                    padding: 3px;
                    margin: 0 5px 0 5px;
                    border-radius: 50%;
                    background-color: #ff5d3b;
                }
                .content-none {
                    padding: 3px;
                    margin: 0 5px 0 5px;
                    border-radius: 50%;
                    background-color: #c7c7c7;
                }
                /*名称的DIV              数量的DIV*/
                .configurationName,.configurationCount{
                    font-size: 15px;
                    font-weight: bold;
                    margin-right: 10px;
                    white-space: nowrap;
                }
                /*select菜单*/
                .configurationSelect{
                    background-color: white;
                    width: 75%;
                    border: none;
                    height: 35px;
                    font-size: 15px;
                    text-align: center;
                    line-height: 35px;
                }
                /*点击用的加号*/
                .changeCount{
                    width: 30px;
                    height: 30px;
                    text-align: center;
                    background-color: #eee;
                    color: #ddd;
                    line-height: 30px;
                    border-radius: 5px;
                }
                /*数量后面的占位DIV*/
                .placeholderDiv{
                    width:115px ;
                    input {
                        width: 90px;
                        height: 32.5px;
                        /*line-height: 40px;*/
                        font-size: 15px;
                        border: none;
                    }
                }
                /*数量值的DIV*/
                .count{
                    text-align: center;
                    width: 20px;
                    color: #D7655A;
                    font-size: 15px;
                    margin: 0 10px;
                    height: 30px;
                    line-height: 30px;
                    border: none;
                    background-color: white;
                }
            }
            /*    备注文本域*/
            .textareaRemarks{
                width: 82%;
                height: 50px;
                margin: 15px auto;
                border-radius: 8px;
                display: block;
                background-color: #f7f7f7;
                border: none;
                font-size: 13px;
                color: #999;
                padding: 15px 15px;
                resize: none;
            }
            /*房屋配置里的   请务必上传*/
            .upLoadText{
                font-size: 12px;
                color: #ff5d3b;
                text-align: right;
                width: 95%;
                margin-bottom: 10px;
            }
        }
    }
    .ruleOne{
        width: 100%;
        border: 0.1px solid #e6e6e6;
    }
    .downImage{
        width: 12px;
        height: 12px;
        margin-right: 10px;
    }
    .upImage{
        width: 12px;
        height: 12px;
        margin-right: 10px;
        transform: rotateX(180deg);
    }
    
    .rightText {
        margin-left: 10px;
        font-size: 14px;
        color: #999999;
    }
    .passText {
        flex: 1;
        padding-right: 7px;
    }
    .choosedStyle {
    color: #ff3c00;
    font-weight: bold;
    }
    .rejectText {
    padding-left: 7px;
    padding-right: 33px;

    }
</style>
